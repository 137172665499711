/**
 * Avalanche Nav & Mega Nav
 * - triggers nav to appear
 */
( function() {
  const megaNavToggles     = document.querySelectorAll('.js-meganav-child-toggle');
  const megaNavContainers  = document.querySelectorAll('.js-meganav-container');
  const megaNavClose       = document.querySelectorAll('.js-meganav-close');
  const navToggle          = document.querySelector('.js-meganav-toggle');
  const navWrap            = document.querySelector('.js-meganav');
  const headerTag          = document.querySelector('.js-header');

  const isVisible = elem => !!elem && !!( elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length )

  function init() {
    if (navWrap) {
      bindEvents();
    }
  }

  function bindEvents() {
    navToggle.addEventListener('click', toggleNavMenu);
    headerTag.addEventListener('keydown', handleNavKeyPress);

    for (var a = 0; a < megaNavClose.length; a++) {
      megaNavClose[a].addEventListener('click', closeMegaNavMenus);
    }

    for (var c = 0; c < megaNavToggles.length; c++) {
      megaNavToggles[c].addEventListener('click', toggleMegaNav);
    }

    for (var e = 0; e < megaNavContainers.length; e++) {
      closeMegaNavOnClickOutside( megaNavContainers[e]);
    }
  }

  // Toggle Basic Nav Menu (via hamburger icon)
  function toggleNavMenu() {
    const isExpanded = navToggle.getAttribute('aria-expanded') === 'true' | false;

    navToggle.setAttribute('aria-expanded', !isExpanded);
    navWrap.classList.toggle('open');
    navWrap.classList.toggle('closed');
    headerTag.classList.toggle('open');
  }

  // Mega Nav Functions
  function toggleMegaNav(event) {
    event.preventDefault();

    const isExpanded  = event.target.getAttribute('aria-expanded') === 'true' | false;
    const megaNavMenu = event.target.nextElementSibling;
    const isOpen      = megaNavMenu.classList.contains('is-open');

    closeMegaNavMenus();
    if(isOpen) {
      megaNavMenu.classList.add('is-open');
    }
    event.target.setAttribute('aria-expanded', !isExpanded);
    megaNavMenu.classList.toggle('is-open');
    megaNavMenu.setAttribute('aria-hidden', !!isExpanded);
  }

  function closeMegaNavMenus() {
    for (var f = 0; f < megaNavContainers.length; f++) {
      megaNavContainers[f].classList.remove('is-open');
      megaNavContainers[f].setAttribute('aria-hidden', true);
    }
    for (var g = 0; g < megaNavToggles.length; g++) {
      megaNavToggles[g].setAttribute('aria-expanded', false);
    }
  }

  function closeMegaNavOnClickOutside(element) {
    const outsideClickListener = event => {
      if(!event.target.classList.contains('js-meganav-child-toggle')) {
        if (!element.contains(event.target) && isVisible(element)) {
          closeMegaNavMenus();
        }
      }
    }
    document.addEventListener('click', outsideClickListener)
  }

  function getMobileLastNavTab() {
    const mobileNavSelectors = [
      '.meganav-action--depth-0',
      '.meganav-action--toggle[aria-expanded="false"]',
      '[tabindex]:not([tabindex="-1"])'
    ].toString();
    const mobileNavTabStopList = Array.from(navWrap.querySelectorAll(mobileNavSelectors));
    const mobileNavTabStops = mobileNavTabStopList.filter(item => {
      return window.getComputedStyle(item).display !== 'none';
    });
    return mobileNavTabStops[mobileNavTabStops.length - 1];
  }

  function getDesktopLastNavTab() {
    const desktopNavSelectors = [
      '.is-open .meganav-action',
      '[tabindex]:not([tabindex="-1"])'
    ].toString();
    const desktopNavTabStopList = Array.from(navWrap.querySelectorAll(desktopNavSelectors));
    const desktopNavTabStops = desktopNavTabStopList.filter(item => {
      return window.getComputedStyle(item).display !== 'none';
    });
    return desktopNavTabStops[desktopNavTabStops.length - 1];
  }

  function handleNavKeyPress(e) {
    const isChildNavOpen = document.querySelector('.is-open .js-meganav-close');
    const isMainNavOpen  = navWrap.classList.contains('open');
    const firstNavTabStop = window.matchMedia('(max-width: 767px)').matches ? navToggle : isChildNavOpen;
    const lastNavTabStop = window.matchMedia('(max-width: 767px)').matches ? getMobileLastNavTab() : getDesktopLastNavTab();

    // Escape key closes a child nav first if open, otherwise closes the main nav
    if (e.keyCode === 27 ) {
      if (isChildNavOpen) {
        closeMegaNavMenus();
      } else if (isMainNavOpen) {
        toggleNavMenu();
      }
    }

    // Trap focus when nav is open
    if (e.keyCode === 9 && (isMainNavOpen || isChildNavOpen)) {
      // SHIFT + TAB
      if (e.shiftKey) {
        if (document.activeElement === firstNavTabStop) {
          e.preventDefault();
          lastNavTabStop.focus();
        }
      // TAB
      } else {
        if (document.activeElement === lastNavTabStop) {
          e.preventDefault();
          firstNavTabStop.focus();
        }
      }
    }
  }

  return init();
} )();
